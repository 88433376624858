<template>
  <div>
    <!-- <el-carousel class="head">
      <el-carousel-item v-for="item in banner" :key="item.id">
        <img :src="item.img" alt="" />
      </el-carousel-item>
    </el-carousel> -->

    <div class="head">
      <img :src="contactInfo.banner" />
      <!-- <h2>联系我们</h2>
      <p>请赶快加入我们</p> -->
    </div>

    <div class="content w">
      <div class="left">
        <img src="../../assets/imgs/contact.png" class="left-img" alt="" />
        <div class="left-phone">
          <div>
            <h2>咨询电话</h2>
            <p>{{ contactInfo.consultationPhone }}</p>
          </div>

          <div>
            <h2>来电时间</h2>
            <p>{{ contactInfo.callTime }}</p>
          </div>
        </div>
      </div>
      <div class="right">
        <div>
          <h2>联系我们</h2>
          <p>生活因你的留言而美</p>
        </div>

        <div class="forms">
          <el-form :model="messageLists" ref="messageRef" :rules="messageRules">
            <el-form-item label="留言" prop="remarks">
              <el-input
                type="textarea"
                v-model="messageLists.remarks"
              ></el-input>
            </el-form-item>
            <el-form-item label="邮箱" prop="email">
              <el-input v-model="messageLists.email"></el-input>
            </el-form-item>
            <el-form-item label="手机" prop="phone">
              <el-input v-model="messageLists.phone"></el-input>
            </el-form-item>
          </el-form>
        </div>

        <div class="send">
          <p>收到您填写的信息后，我们会通过手机或邮件与您联系</p>
          <el-button @click="handleSend('messageRef')">发送</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { addMessage, getContactInfo } from '../../api/contact'
import { getBnners } from '../../api/banner.js'
export default {
  data() {
    var checkEmail = (rule, value, callback) => {
      const regEmail =
        /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/
      if (regEmail.test(value)) {
        return callback()
      }
      callback(new Error('请输入合格的邮箱'))
    }
    var checkMobile = (rule, value, callback) => {
      const regMobile =
        /^(0|86|17951)?(13[0-9]|15[012356789]|17[3678]|18[0-9]|14[57])[0-9]{8}$/
      if (regMobile.test(value)) {
        return callback()
      }
      callback(new Error('请输入合格的手机号'))
    }
    return {
      contactInfo: {
        consultationPhone: '',
        callTime: ''
      },

      messageLists: {
        remarks: '',
        email: '',
        phone: ''
      },
      messageRules: {
        remarks: [
          { required: true, message: '请输入留言内容', trigger: 'blur' }
        ],

        email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' },
          { validator: checkEmail, trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          {
            validator: checkMobile,
            trigger: 'blur'
          }
        ]
      },
      banner: []
    }
  },
  created() {
    this._initData()
  },
  methods: {
    _initData() {
      getContactInfo().then((res) => {
        if (res.status === 200) {
          this.contactInfo = res.data
            ? res.data
            : {
                consultationPhone: '',
                callTime: ''
              }
        }
      })
      getBnners({ classifyId: -4 }).then((res) => {
        this.banner = res.data
      })
    },
    handleSend(formName) {
      let message = JSON.parse(JSON.stringify(this.messageLists))
      this.$refs[formName].validate((valid) => {
        if (valid) {
          addMessage(message).then(() => {
            this.$refs[formName].resetFields()
            this.$message({
              type: 'success',
              message: '添加留言成功'
            })
          })
        } else {
          this.$message({
            type: 'error',
            message: '添加留言失败'
          })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
/deep/.el-carousel__container {
  height: 240px;
  img {
    width: 100%;
    height: 100%;
  }
}
.head {
  width: 100%;
  height: 400px;
  margin-bottom: 10px;
  img {
    width: 100%;
    height: 100%;
  }
  // height: 400px;
  // background-image: url("../../assets/imgs/about/about.png");
  // margin-bottom: 100px;
  // font-family: "Microsoft YaHei";
  // font-style: normal;
  // font-weight: 400;
  // color: #ffffff;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // justify-content: center;
  // h2 {
  //   font-size: 40px;
  //   line-height: 48px;
  //   margin-bottom: 40px;
  // }
  // p {
  //   font-size: 28px;
  //   line-height: 48px;
  // }
}
.content {
  display: flex;
  padding: 100px 0 130px 0;
  box-sizing: border-box;
  .left {
    width: 471px;
    margin-right: 20px;
    .left-img {
      width: 471px;
      height: 354px;
    }
    .left-phone {
      // float: right;
      width: 279px;
      height: 219px;
      background: #eff0f2;
      border-radius: 24px;
      padding: 40px 16px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      margin: 0 auto;
      div {
        font-style: normal;

        h2 {
          font-family: 'OPPOSans-R';
          color: #9a9a9a;
          font-weight: 700;
          margin-bottom: 14px;
          font-size: 14px;
          line-height: 18px;
        }
        p {
          font-family: 'OPPOSans-M';
          letter-spacing: 1px;
          font-weight: 700;
          color: #484848;
          font-size: 18px;
          line-height: 24px;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
  }
  .right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;
    font-style: normal;
    font-weight: 700;

    h2 {
      font-size: 28px;
      font-weight: 700;
      line-height: 37px;
      color: #484848;
      margin-bottom: 10px;
    }
    p {
      font-size: 14px;
      font-family: 'OPPOSans-M';
      line-height: 18px;
      color: #9a9a9a;
    }
    .forms {
      caret-color: #000;
      width: 780px;
    }
    .send {
      display: flex;
      align-items: center;
      justify-content: space-between;
      p {
        font-family: 'OPPOSans-M';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
      }
    }
  }
}

/deep/.el-button {
  width: 119px;
  height: 56px;
  background: #00bbf0;
  border-radius: 28px;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #ffffff;
  font-family: 'OPPOSans';
}

/deep/.el-textarea__inner {
  height: 124px;
}

/deep/.el-form-item__error {
  margin-top: 4px;
  font-weight: 700;
}

.el-form-item__label {
  font-size: 15px;
  font-family: 'OPPOSans-R';
}
</style>
